<template>
  <div>
    <b-container fluid class="p-0 mt-0">
      <b-row>
        <b-col cols="1"/>
        <b-col cols="10">
          <SubHeader>
            <b-row class="mt-2 mb-1">
              <b-col cols="auto">
                <div class="text-white">Einzelansicht von {{ computer.hostname }}</div>
              </b-col>
              <b-col cols="auto" class="ml-auto">
                <small class="ml-2">Datenabruf um: {{ lastDataFetched }}</small>
              </b-col>
            </b-row>
          </SubHeader>
          <ComputerRowSingle v-if="this.computer" :computer="computer" class="mt-3" @refresh="refresh"/>
        </b-col>
        <b-col cols="1"/>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {mapActions} from "vuex";
import {getRequest} from "@/modules/requests";
import SubHeader from "@/components/structure/SubHeader";
import ComputerRowSingle from "@/components/singleDisplay/ComputerRowSingle";

export default {
  name: "Computer",
  components: {
    SubHeader,
    ComputerRowSingle
  },
  data() {
    return {
      computer: null,
      lastDataFetched: '-'
    }
  },
  methods: {
    ...mapActions(['setLoadingState']),
    async getComputer() {
      await getRequest('computer/' + this.hostname, {id: this.id}, this)
          .then((response) => {
            this.computer = response.data
            document.title = this.computer.hostname + ' · alfaSMS';
          })
    },
    async refresh() {
      this.setLoadingState(true)
      await this.getComputer()
      this.lastDataFetched = dayjs().format('HH:mm:ss')
      await window.scrollTo(0, 0)
      this.setLoadingState(false)
    }
  },
  computed: {
    id() {
      if(this.$route.query.id) {
        return this.$route.query.id
      }
      return null
    },
    hostname() {
      if(this.$route.params.hostname) {
        return this.$route.params.hostname
      }
      return null
    }
  },
  created() {
    this.refresh()
  }
}
</script>

<style lang="scss" scoped>
</style>
